import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  useGetIdentity,
  useGetList,
  useListContext,
  useRecordContext,
  useUpdate,
} from 'react-admin';

import CustomPagination from '../CustomPagination';
import { ButtonGroup, Grid, IconButton, Tooltip } from '@mui/material';
import { ReferenceManyInput } from '@react-admin/ra-relationships';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { findIndex, maxBy } from 'lodash';
import ReOrderButtons from '../components/ReOrderButtons';

const redirect = () => 'Product';

const ProductForm = () => {
  const record = useRecordContext();
  const { identity } = useGetIdentity();

  return (
    <Grid container spacing={12}>
      <Grid item md={4}>
        <TextInput source="name" fullWidth />
        <br />
        <NumberInput source="price" fullWidth />
        <br />
        <TextInput multiline rows={5} source="description" fullWidth />
        <br />
        <ReferenceInput reference="Category" source="category_id">
          <SelectInput optionText="name" fullWidth />
        </ReferenceInput>
        <ReferenceInput
          reference="User"
          source="user"
          defaultValue={identity?.id}>
          <SelectInput
            style={{ display: 'none' }}
            optionText="email"
            defaultValue={identity?.id}
          />
        </ReferenceInput>
        <br />
        <ReferenceInput reference="Taxe" source="taxe">
          <SelectInput optionText="amount" fullWidth />
        </ReferenceInput>
        <br />
        <BooleanInput source="autoIncrementAllow" />
        <br />
        <BooleanInput source="autoOpenExtras" />
      </Grid>
      <Grid item md={8}>
        {record?.id && (
          <>
            List of ingrediences
            <ReferenceManyInput
              reference="ProductIngredience"
              target="productId_ids">
              <SimpleFormIterator inline>
                <ReferenceInput reference="Ingredience" source="ingredience_id">
                  <SelectInput optionText="name" fullWidth />
                </ReferenceInput>
                <NumberInput source="amount" />
                <TextInput
                  source="product_id"
                  defaultValue={record?.id}
                  style={{ display: 'none' }}
                />
              </SimpleFormIterator>
            </ReferenceManyInput>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export const ProductCreate = (props: any) => {
  const { data } = useGetList('Product');
  return (
    <Create {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm defaultValues={{ orderIndex: data?.length || 0 }}>
        <ProductForm />
      </SimpleForm>
    </Create>
  );
};

export const ProductEdit = (props: any) => {
  return (
    <Edit {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm>
        <ProductForm />
      </SimpleForm>
    </Edit>
  );
};

export const ProductFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="name" />
    <NumberInput source="price" />
    <ReferenceInput reference="Category" source="categoryId">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const ProductList = (props: any) => {
  return (
    <List
      {...props}
      filters={<ProductFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <FunctionField
          source="orderIndex"
          render={() => (
            <ReOrderButtons source="orderIndex" resource="Product" label="" />
          )}
        />
        <TextField source="name" />
        <FunctionField
          source="price"
          render={(record) => `${record.price},-`}
        />
        <ReferenceField source="categoryId" reference="Category">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="autoOpenExtras" />
        <BooleanField source="autoIncrementAllow" />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
};

const fragment = gql`
  fragment ProductFragment on Product {
    productIngrediences {
      id
      amount
      ingredience {
        id
        name
        priceHistoryIngrediences {
          id
          price
          createdAt
        }
      }
    }
  }
`;

export const Product: ResourceView = {
  resource: 'Product',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
  },
};
