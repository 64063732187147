import './App.css';

import {
  Admin,
  CREATE,
  CustomRoutes,
  DELETE,
  Login,
  Resource,
  UPDATE,
} from 'react-admin';
import {
  Attendance,
  AttendanceCreate,
  AttendanceEdit,
  AttendanceList,
} from './modules/attendance';
import {
  BestSeller,
  BestSellerCreate,
  BestSellerEdit,
  BestSellerList,
} from './modules/bestSeller';
import {
  Cashbox,
  CashboxCreate,
  CashboxEdit,
  CashboxList,
} from './modules/cashbox';
import { CategoryCreate, CategoryEdit, CategoryList } from './modules/category';
import { ExtraCreate, ExtraEdit, ExtraList } from './modules/extra';
import { VoucherCreate, VoucherEdit, VoucherList } from './modules/voucher';
import {
  Ingredience,
  IngredienceCreate,
  IngredienceEdit,
  IngredienceList,
} from './modules/ingredience';
import {
  Inventory,
  InventoryCreate,
  InventoryEdit,
  InventoryList,
} from './modules/inventory';
import { Order, OrderList } from './modules/orders';
import {
  Product,
  ProductCreate,
  ProductEdit,
  ProductList,
} from './modules/product';
import {
  SingleExpenseCreate,
  SingleExpenseEdit,
  SingleExpenseList,
} from './modules/singleExpense';
import { UserCreate, UserEdit, UsersList } from './modules/users';
import styled, { createGlobalStyle } from 'styled-components';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { CircularProgress } from '@material-ui/core';
import CustomLayout from './CustomLayout';
import CustomTheme from './CustomTheme';
import { Divider } from '@mui/material';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import LoginForm from './modules/login';
import MoneyIcon from '@mui/icons-material/Money';
import { Options } from '@ra-data-prisma/dataprovider';
import OrderEdit from './modules/orderEdit';
import { ProductIngredience } from './modules/productIngredience';
import ReportPage from './modules/report';
import { Resource as ResourceDataprovider } from '@ra-data-prisma/dataprovider/lib/constants/interfaces';
import { Route } from 'react-router-dom';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { appProvidersFactory } from './appProvidersFactory';
import i18nProvider from './locales';
import { makePrefixedFullName } from '@ra-data-prisma/dataprovider/lib/utils/makePrefixedFullName';
import schema from '../src/graphql-schema.json';
import themeReducer from './reducers/theme';

const Center = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const SpinnerWrapper = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
`;

const App: React.VFC<{ client: any }> = ({ client }) => {
  const options: Options = {
    client,
    introspection: {
      schema: (schema as any).__schema,
    },
    queryDialect: 'typegraphql',
    resourceViews: {
      Order,
      Cashbox,
      ProductIngredience,
      Ingredience,
      Product,
      BestSeller,
      Inventory,
      Attendance,
    },
    mutationOperationNames: {
      typegraphql: {
        [CREATE]: (resource: ResourceDataprovider) =>
          makePrefixedFullName(`createOne${resource.name}`),
        [UPDATE]: (resource: ResourceDataprovider) =>
          makePrefixedFullName(`updateOne${resource.name}`),
        [DELETE]: (resource: ResourceDataprovider) =>
          makePrefixedFullName(`deleteOne${resource.name}`),
      },
    },
  };

  const { dataProvider, authProvider } = appProvidersFactory()(options);

  if (!dataProvider) {
    return (
      <Center>
        <SpinnerWrapper />
        <CircularProgress />
      </Center>
    );
  }

  return (
    <Admin
      dataProvider={dataProvider}
      disableTelemetry={true}
      layout={CustomLayout}
      i18nProvider={i18nProvider}
      theme={CustomTheme}
      customReducers={{ theme: themeReducer }}
      loginPage={(props) => (
        <Login {...props}>
          <LoginForm />
        </Login>
      )}
      authProvider={authProvider}>
      <Resource
        name="User"
        list={UsersList}
        edit={UserEdit}
        create={UserCreate}
        icon={AssignmentIndIcon}
      />
      <Resource
        name="BestSeller"
        list={BestSellerList}
        edit={BestSellerEdit}
        create={BestSellerCreate}
        icon={StarBorderIcon}
      />
      <Resource
        name="Category"
        list={CategoryList}
        edit={CategoryEdit}
        create={CategoryCreate}
        icon={AccountTreeIcon}
      />
      <Resource
        name="Product"
        list={ProductList}
        edit={ProductEdit}
        create={ProductCreate}
        icon={Inventory2Icon}
      />
      <Resource
        name="Inventory"
        list={InventoryList}
        edit={InventoryEdit}
        create={InventoryCreate}
        icon={Inventory2Icon}
      />
      <Resource
        name="Attendance"
        list={AttendanceList}
        edit={AttendanceEdit}
        create={AttendanceCreate}
        icon={Inventory2Icon}
      />
      <Divider />
      <Resource
        name="Extra"
        list={ExtraList}
        edit={ExtraEdit}
        create={ExtraCreate}
        icon={AddLinkIcon}
      />
      <Resource
        name="Voucher"
        list={VoucherList}
        edit={VoucherEdit}
        create={VoucherCreate}
        icon={LoyaltyIcon}
      />
      <Resource
        name="Ingredience"
        list={IngredienceList}
        edit={IngredienceEdit}
        create={IngredienceCreate}
        icon={GroupWorkIcon}
      />
      <Resource
        name="Order"
        list={OrderList}
        edit={OrderEdit}
        icon={ShoppingBasketIcon}
      />
      <Resource
        name="Cashbox"
        options={{ label: 'Cashbox' }}
        list={CashboxList}
        edit={CashboxEdit}
        create={CashboxCreate}
        icon={MoneyIcon}
      />
      <Resource
        name="SingleExpense"
        options={{ label: 'Expenses' }}
        list={SingleExpenseList}
        edit={SingleExpenseEdit}
        create={SingleExpenseCreate}
        icon={ShoppingCartCheckoutIcon}
      />
      <CustomRoutes>
        <Route path="/Report" element={<ReportPage />} />
      </CustomRoutes>
      <Resource name="ProductIngredience" />,
      <Resource name="PriceHistoryIngredience" />,
      <Resource name="ProductInventory" />,
      <Resource name="CashboxItem" />,
    </Admin>
  );
};

export default App;
