import {
  Create,
  Datagrid,
  DeleteButton,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useGetIdentity,
  useGetList,
} from 'react-admin';

import CustomPagination from '../CustomPagination';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';
import ReOrderButtons from '../components/ReOrderButtons';

const redirect = () => 'BestSeller';

const BestSellerForm = () => {
  return (
    <div>
      <ReferenceInput reference="Product" source="product_id">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </div>
  );
};

export const BestSellerCreate = (props: any) => {
  const { data } = useGetList('BestSeller');
  return (
    <Create {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm
        defaultValues={{
          orderIndex: data?.length || 0,
        }}>
        <BestSellerForm />
      </SimpleForm>
    </Create>
  );
};

export const BestSellerEdit = (props: any) => {
  return (
    <Edit {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm>
        <BestSellerForm />
      </SimpleForm>
    </Edit>
  );
};

export const BestSellerFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="name" />
  </Filter>
);

export const BestSellerList = (props: any) => {
  return (
    <List
      {...props}
      sort={{
        field: 'orderIndex',
        order: 'ASC',
      }}
      filters={<BestSellerFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <FunctionField
          source="orderIndex"
          render={() => (
            <ReOrderButtons source="orderIndex" resource="BestSeller" label="" />
          )}
        />
        <TextField source="product.name" />
        <EditButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};

const fragment = gql`
  fragment BestSellerFragment on BestSeller {
    product {
      id
      name
    }
  }
`;

export const BestSeller: ResourceView = {
  resource: 'BestSeller',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
  },
};
