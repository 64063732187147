import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useGetList,
  useRecordContext,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';

import { CashboxItem, Order, OrderStatus } from '../@generated/schemas';
import CustomPagination from '../CustomPagination';
import { ReferenceManyInput } from '@react-admin/ra-relationships';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';
import { pick } from 'lodash';
import { useFormContext } from 'react-hook-form';

const redirect = () => 'Cashbox';

const getFinalAmountCashbox = (cashboxItems: CashboxItem[]) =>
  cashboxItems?.reduce((result, item) => result + item.kind * item.amount, 0) || 0;
const getFinalAmountOrdersCash = (orders: Order[]) =>
  orders?.reduce((result, item) => result + item.totalPrice, 0) || 0;

const getTransformedData = (data) => {
  data['@@ra-many/Cashbox/CashboxItem/cashboxId_ids'] = data[
    '@@ra-many/Cashbox/CashboxItem/cashboxId_ids'
  ]?.map((cashbox_id) => {
    return {
      CashboxItem: cashbox_id.CashboxItem.map((cashboxItem) => ({
        ...pick(cashboxItem, ['kind', 'amount', cashboxItem?.id ? 'id' : '', 'cashbox_id']),
      })),
    };
  });

  return data;
};

const CashboxForm = () => {
  const record = useRecordContext();
  const { watch } = useFormContext();
  const cashboxItems =
    watch('@@ra-many/Cashbox/CashboxItem/cashboxId_ids')?.[0]?.CashboxItem || [];
  const ordersDateFrom = watch('ordersDateFrom');
  const ordersDateTo = watch('ordersDateTo');

  const { data } = useGetList("Order", {
    filter: {
      createdAt: {
        gte: ordersDateFrom,
        lte: ordersDateTo,
      },
      deletedAt: {
        equals: null,
      },
      status: {
        in: [OrderStatus.Cash, OrderStatus.Card]
      }
    }
  });

  const finalAmountCashbox = getFinalAmountCashbox(cashboxItems);
  const finalAmountOrders = getFinalAmountOrdersCash(data);

  return (
    <Grid container spacing={12}>
      <Grid item md={4}>
        <DateTimeInput source="dateAt" />
        <br />
        <DateTimeInput source="ordersDateFrom" />
        <DateTimeInput source="ordersDateTo" />
        {record?.id && (
          <ReferenceManyInput
            label="Items"
            reference="CashboxItem"
            sort={{ field: 'kind', order: 'ASC' }}
            target="cashboxId_ids">
            <SimpleFormIterator inline fullWidth disableClear>
              <SelectInput
                source="kind"
                choices={[
                  1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000,
                ].map((amount) => ({
                  id: amount,
                  name: amount,
                }))}
              />
              <NumberInput source="amount" defaultValue={0} />
              <TextInput
                source="cashbox_id"
                defaultValue={record?.id}
                style={{ display: 'none' }}
              />
            </SimpleFormIterator>
          </ReferenceManyInput>
        )}
      </Grid>
      <Grid item md={4}>
        <Typography>Final amount: {finalAmountCashbox},-</Typography>
        <Typography>Total orders price (cash and cards): {finalAmountOrders},-</Typography>
        <Typography>Tips: {finalAmountCashbox - finalAmountOrders},-</Typography>
      </Grid>
    </Grid>
  );
};

export const CashboxCreate = (props: any) => {
  return (
    <Create {...props} transform={getTransformedData} mutationMode="optimistic">
      <SimpleForm defaultValues={{ dateAt: new Date() }}>
        <CashboxForm />
      </SimpleForm>
    </Create>
  );
};

export const CashboxEdit = (props: any) => {
  return (
    <Edit
      {...props}
      transform={getTransformedData}
      mutationMode="optimistic"
      redirect={redirect}>
      <SimpleForm>
        <CashboxForm />
      </SimpleForm>
    </Edit>
  );
};

export const CashboxFilter = (props: any) => (
  <Filter {...props}>
    <DateInput source="dateAt" alwaysOn />
    <DateInput source="ordersDateFrom_gte" alwaysOn />
    <DateInput source="ordersDateTo_lte" alwaysOn />
  </Filter>
);

export const CashboxList = (props: any) => {
  return (
    <List
      {...props}
      sort={{
        field: 'orderIndex',
        order: 'ASC',
      }}
      filters={<CashboxFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <DateField source="dateAt" showTime />
        <DateField source="ordersDateFrom" showTime />
        <DateField source="ordersDateTo" showTime />
        <FunctionField
          source="amount"
          render={(row) => `${getFinalAmountCashbox(row?.cashboxItems || [])},-`}
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const CashboxFragment = gql`
  fragment CashboxFragment on Cashbox {
    cashboxItems {
      id
      kind
      amount
    }
  }
`;

export const Cashbox: ResourceView = {
  resource: 'Cashbox',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: CashboxFragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: CashboxFragment,
    },
  },
};
