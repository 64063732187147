import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

import CustomPagination from '../CustomPagination';

const redirect = () => 'Voucher';

const VoucherForm = () => {
  return (
    <div>
      <TextInput source="code" style={{ width: 250 }} />
      <br />
      <NumberInput source="price" />
      <br />
      <TextInput source="description" style={{ width: 250 }} />
      <br />
      <DateTimeInput source="expiredAt" style={{ width: 250 }} />
    </div>
  );
};

export const VoucherCreate = (props: any) => {
  return (
    <Create {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm>
        <VoucherForm />
      </SimpleForm>
    </Create>
  );
};

export const VoucherEdit = (props: any) => {
  return (
    <Edit {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm>
        <VoucherForm />
      </SimpleForm>
    </Edit>
  );
};

export const VoucherFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="code" />
    <NumberInput source="price" />
    <BooleanInput source="isCreatedByApp" label="Created by app" />
  </Filter>
);

export const VoucherList = (props: any) => {
  return (
    <List
      {...props}
      filters={<VoucherFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="code" />
        <FunctionField
          source="price"
          render={(record) => `${record.price},-`}
        />
        <TextField source="description" />
        <FunctionField source="isUsed" sortable={false} render={row => row.orders.length > 0 ? 'Yes' : 'No'} />
        <DateField source="expiredAt" showTime />
        <DateField source="createdAt" showTime />
        <BooleanField source="isCreatedByApp" label="Created by app" />
        <EditButton />
        <DeleteWithConfirmButton mutationMode='pessimistic' />
      </Datagrid>
    </List>
  );
};
