import './index.css';

import { ApolloProvider } from '@apollo/client';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import createApolloClient from './libs/createApolloClient';

const getUrl = () => {
  if (window.location.href.includes('knappuccino.cz')) {
    return `https://api.knappuccino.cz/admin/graphql`;
  }

  return window.localStorage.getItem('API_URL') || 'http://localhost:3069/admin/graphql';
};

export const url = getUrl();

const client = createApolloClient(url);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App client={client} />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
