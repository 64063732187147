import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DeleteButton,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  List,
  NullableBooleanInput,
  PasswordInput,
  SimpleForm,
  TextField,
  TextInput,
  email,
} from 'react-admin';

import CustomPagination from '../CustomPagination';

const UserForm = () => {
  const validateEmail = email();

  return (
    <div>
      <TextInput
        type="email"
        source="email"
        validate={validateEmail}
        style={{ width: 250 }}
      />
      <br />
      <PasswordInput source="password" style={{ width: 250 }} />
      <BooleanInput source="isAdmin" />
    </div>
  );
};

export const UserCreate = (props: any) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <UserForm />
    </SimpleForm>
  </Create>
);

export const UserEdit = (props: any) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <UserForm />
    </SimpleForm>
  </Edit>
);

export const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="email" />
    <NullableBooleanInput source="isAdmin" />
  </Filter>
);

export const UsersList = (props: any) => {
  return (
    <List
      {...props}
      filters={<UserFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="email" />
        <BooleanField source="isAdmin" />
        <EditButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};
